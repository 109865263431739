import React, { forwardRef, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import DrawerFaturaAberta from "./DrawerFaturaAberta";
import ColorButton from "../Button/colorbutton";
import dayjs from "dayjs";
import "./styles.css";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const numberFormat = (value) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

function Tabela({ faturas, downloadPDF }) {
  const [statusModal, setStatusModal] = useState(false);
  const [faturaSelecionada, setFaturaSelecionada] = useState(null);
  const [stateSnackbar, setStateSnackbar] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = stateSnackbar;

  const handleAbrirModal = useCallback(() => setStatusModal(true), []);
  const handleFecharModal = useCallback(() => setStatusModal(false), []);

  const mostrarSnackbar = useCallback(
    (newState) => () => {
      setStateSnackbar({ open: true, ...newState });
    },
    []
  );

  const fecharSnackbar = useCallback(() => {
    setStateSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  const handleClick = useCallback(
    (event, fatura) => {
      setFaturaSelecionada(fatura);
      handleAbrirModal();
    },
    [handleAbrirModal]
  );

  return (
    <>
      <div className="card card-xl-stretch mb-xl-8">
        <div className="card-body py-3">
          <form className="form" id="searchOrder">
            <div className="d-flex align-items-center">
              <div className="position-relative min-w-400px me-2">
                <div className="row g-12">
                  <div className="col-12">
                    <Typography gutterBottom variant="h5">
                      {faturas.length > 0
                        ? "Faturas em aberto"
                        : "Não há faturas em aberto"}
                    </Typography>

                    {faturas.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "flex-start",
                          "& > :not(style)": {
                            m: 1,
                            width: 138,
                            height: "100%",
                          },
                        }}
                      >
                        {faturas.map((fatura) => (
                          <section
                            key={fatura.cd_prl}
                            style={{ margin: "3px", padding: "3px" }}
                          >
                            <Paper elevation={3}>
                              <Card sx={{ minWidth: 135 }} variant="outlined">
                                <CardActionArea
                                  onClick={(event) =>
                                    handleClick(event, fatura)
                                  }
                                >
                                  <CardContent>
                                    <span
                                      className={`badge ${
                                        dayjs().diff(
                                          dayjs(fatura.dt_venctobase),
                                          "day"
                                        ) > 30
                                          ? "badge-danger"
                                          : dayjs().diff(
                                              dayjs(fatura.dt_venctobase),
                                              "day"
                                            ) > 0
                                          ? "badge-warning"
                                          : "badge-success"
                                      }`}
                                    >
                                      {dayjs().diff(
                                        dayjs(fatura.dt_venctobase),
                                        "day"
                                      ) > 30
                                        ? "Vencido"
                                        : dayjs().diff(
                                            dayjs(fatura.dt_venctobase),
                                            "day"
                                          ) > 0
                                        ? "Atrasado"
                                        : "Em aberto"}
                                    </span>
                                    <Typography gutterBottom variant="body2">
                                      {dayjs(fatura.dt_venctobase).format(
                                        "MMMM YYYY"
                                      )}
                                    </Typography>
                                    <Typography gutterBottom variant="h5">
                                      {numberFormat(fatura.vl_total)}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {dayjs(fatura.dt_venctobase).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </Typography>
                                    <div style={{ marginTop: "10px" }}>
                                      <ColorButton
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleClick(event, fatura);
                                        }}
                                      >
                                        Ver boleto
                                      </ColorButton>
                                    </div>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Paper>
                          </section>
                        ))}
                      </Box>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {faturaSelecionada && (
        <DrawerFaturaAberta
          fatura={faturaSelecionada}
          statusModal={statusModal}
          handleFecharModal={handleFecharModal}
          mostrarSnackbar={mostrarSnackbar}
          downloadPDF={downloadPDF}
        />
      )}

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={fecharSnackbar}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert onClose={fecharSnackbar} severity="success" sx={{ width: 200 }}>
          Código copiado!
        </Alert>
      </Snackbar>
    </>
  );
}

export default Tabela;
